import http from "@/services/http.service";

const apiUrl = "/fertility-records";

class FertilityRecordsService {
  static list(params) {
    return http.get(`${apiUrl}`, {params});
  }

  static create(payload) {
    return http.post('records', payload);
  }

  static createFertilityFields(payload) {
    return http.post('store-fertility-fields', payload);
  }

  static edit(payload) {
    return http.put('records-update', payload);
  }

  static get(farmId, harvest) {
    return http.get(`${apiUrl}/farms/${farmId}/harvests/${harvest}`);
  }

  static getFertilityRecordFields(farmId, harvest) {
    return http.get(`fertility-record-fields/farms/${farmId}/harvests/${harvest}`);
  }

  static getFertilityRecordByField(fieldId, harvest) {
    return http.get(`fertility-records/fields/${fieldId}/harvests/${harvest}`);
  }

  static delete(payload) {
    return http.delete('records-destroy', payload);
  }
}

export default FertilityRecordsService;
