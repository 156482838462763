import FertilityRecordsService from "./fertility-records.service";

class FertilityRecord {
  constructor(payload = {}) {
    Object.assign(this, {
      ...FertilityRecord.build(payload)
    });
  }

  static build(
    {
      id               = '',
      grade            = '',
      harvest          = '',
      field_id         = '',
      field            = {},
      farm_id          = '',
      farm             = {},
      index_id         = '',
      indexes          = {},
      producer_id      = '',
      producer         = '',
      farmDetails      = {},
      farmsByProducer  = {},
      fields_by_farm   = {},
      culture          = '',
      recordDate       = '',
      recordIql        = '',
      iql_record_field = '',
      created_at       = '',
      updated_at       = ''
    }
  ) {
    return {
      id,
      grade,
      harvest,
      field_id,
      field,
      farm_id,
      farm,
      index_id,
      indexes,
      producer_id,
      producer,
      farmDetails,
      farmsByProducer,
      fields_by_farm,
      culture,
      recordDate,
      recordIql,
      iql_record_field,
      created_at,
      updated_at
    }
  }

  static newData(payload) {
    return new FertilityRecord(payload);
  }
  
  static list(params) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
        .list(params)
        .then(
          success => {
            const data = {...success};
            const list = Object.assign({}, {data});
            list.data  = data.data.map(item => this.newData(item));
            resolve(list);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
        .create(payload)
        .then(
          success => {
            resolve(success);
          },
          
          error => {
            reject(error);
          }
        );
    });
  }

  static createFertilityFields(payload) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
        .createFertilityFields(payload)
        .then(
          success => {
            resolve(success);
          },
          
          error => {
            reject(error);
          }
        );
    });
  }

  static edit(payload) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
      .edit(payload)
      .then(
        success => {
          resolve(success);
        },
        
        error => {
          reject(error);
        }
      );
    })
  }

  static get(farmId, harvest) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
      .get(farmId, harvest)
      .then(
        success => {
          const fertilityRecord = this.newData(success.data.data);
          resolve(fertilityRecord);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }

  static getFertilityRecordFields(farmId, harvest) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
      .getFertilityRecordFields(farmId, harvest)
      .then(
        success => {
          const response = success;
          resolve(response);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }

  static getFertilityRecordByField(fieldId, harvest) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
      .getFertilityRecordByField(fieldId, harvest)
      .then(
        success => {
          const response = success;
          response.data  = this.newData(response.data.data);
          resolve(response);
        },

        error => {
          reject(error);
        }
      )
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      FertilityRecordsService
        .delete(payload)
        .then((success) => {
          resolve(success);
        }, (error) => {
          reject(error);
        });
    });
  }
}

export default FertilityRecord;
